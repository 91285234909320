<template>
  <div style="background: #011B5D">
    <div class="w-100" style="position: fixed; top: 15px; text-align: center">
      <h3 id="title" class="d-inline">数字农场</h3>
      <!--<div class="hide">Test</div>-->
    </div>

    <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 103px); width: 28%">
          <div class="frame" style="height: 33%;">
            <div class="title title1"></div>
            <div class="frame-container">
              <div class="data-row">
                <div class="data-item">
                  <img src="@/assets/guangzhao.png" />
                  <span class="span1" style="color: #FFEC1A">{{ farm.guangzhao }}%</span>
                  <span class="span2">光照</span>
                </div>
                <div class="data-item">
                  <img src="@/assets/kongqiwendu.png" style="margin-bottom: 8px" />
                  <span class="span1" style="color: #E560FF">{{ farm.kongqiwendu }}℃</span>
                  <span class="span2" >空气温度</span>
                </div>
                <div class="data-item">
                  <img src="@/assets/kongqishidu.png" style="margin-bottom: 12px" />
                  <span class="span1" style="color: #30E4F3">{{ farm.kongqishidu }}%</span>
                  <span class="span2">空气湿度</span>
                </div>
              </div>
              <div class="data-row">
                <div class="data-item">
                  <img src="@/assets/jiangshuiliang.png" />
                  <span class="span1" style="color: #0EB8FF">{{ farm.jiangshuiliang }}mm</span>
                  <span class="span2">降水量</span>
                </div>
                <div class="data-item">
                  <img src="@/assets/zhengfaliang.png" style="margin-bottom: 8px" />
                  <span class="span1" style="color: #FFEC1A">{{ farm.zhengfaliang }}mm</span>
                  <span class="span2">蒸发量</span>
                </div>
                <div class="data-item">
                  <img src="@/assets/co2.png" style="margin-bottom: 14px" />
                  <span class="span1" style="color: #E560FF">{{ farm.co2 }}%</span>
                  <span class="span2">二氧化碳</span>
                </div>
              </div>
            </div>
          </div>
          <div class="frame" style="height: 65%">
            <div class="title title2"></div>
            <div class="frame-container">
              <div id="chart1" style="width: 100%; height: 45%;"></div>
              <div id="chart2" style="width: 100%; height: 45%;"></div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 103px); width: 42%">
          <div id="map" style="height: 66%">
            <!--<img class="img-fluid" src="image/ag.jpg" usemap="#workmap" style="height: 100%; width: 100%; object-fit: cover;" />-->
            <!--<map name="workmap">-->
            <!--  <area shape="rect" coords="100,200,300,400" href="#">-->
            <!--</map>-->
            <div class="d-flex flex-column justify-content-around" style="height: 100%; width: 100%">
              <div class="d-flex" style="height: 50%; width: 100%">
                <img class="img-fluid" :src="imgUrl1" style="height: 100%; width: 36%; object-fit: fill;" @click="switchArea(1)" />
                <img class="img-fluid" :src="imgUrl2" style="height: 100%; width: 28%; object-fit: fill;" @click="switchArea(2)" />
                <img class="img-fluid" :src="imgUrl3" style="height: 100%; width: 36%; object-fit: fill;" @click="switchArea(3)" />
              </div>
              <div class="d-flex" style="height: 50%; width: 100%">
                <img class="img-fluid" :src="imgUrl4" style="height: 100%; width: 66%; object-fit: fill;" @click="switchArea(4)" />
                <img class="img-fluid" :src="imgUrl5" style="height: 100%; width: 33%; object-fit: fill;" @click="switchArea(5)" />
              </div>
            </div>
          </div>
          <div class="frame" style="height: 32%; padding: 10px 10px">
            <table class="table table-borderless">
              <thead style="color: #3E93DB">
              <tr>
                <th>地块编号</th>
                <th>地块面积（亩）</th>
                <th>种植作物</th>
                <th>上次施肥</th>
                <th>浇水记录</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in rows" :key="row.no">
                <td>{{ row.no }}</td>
                <td>{{ row.area }}</td>
                <td>{{ row.crop }}</td>
                <td>{{ row.date1 }}</td>
                <td>{{ row.date2 }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 103px); width: 28%">
          <div class="frame" style="height: 32%">
            <div class="frame-container h-100">
              <div class="title title3"></div>
              <!--<div style="margin-top: 10px; margin-left: 15px">盐度（电导率）</div>-->
              <div id="chart3" style="width: 100%; height: 30%; margin-top: 15px"></div>
              <div class="d-flex flex-column justify-content-center align-self-center" style="width: 90%; height: 30%; margin-top: -20px">
                <!--<img class="img-fluid" src="@/assets/ph.jpg" />-->
                <!--<div class="d-flex justify-content-between">-->
                <!--  <span>PH值</span>-->
                <!--  <span>{{ farm.ph }}</span>-->
                <!--  <span>&nbsp;</span>-->
                <!--</div>-->
                <span>PH值：<span style="color: #FAEB53">{{ farm.ph }}</span></span>
                <img class="img-fluid" :src="ph[farm.ph - 1]" />
              </div>
              <div class="d-flex align-items-end" style="width: 100%; height: 30%; justify-content: space-evenly; margin-bottom: 15px">
                <div class="d-flex flex-column align-items-center">
                  <span class="" style="font-size: 14px">土壤温度</span>
                  <span class="" style="color: #D66CF5; font-size: 28px">{{ farm.turangwendu }}℃</span>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <span class="" style="font-size: 14px">土壤湿度</span>
                  <span class="" style="color: #6EE2F0; font-size: 28px">{{ farm.turangshidu }}%</span>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <span class="" style="font-size: 14px">土壤氮磷钾</span>
                  <span class="" style="color: #FAEB53; font-size: 28px">{{ farm.danlinjia }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="frame" style="height: 32%">
            <div class="frame-container justify-content-between">
              <div class="title title4"></div>
              <div style="height: calc(100% - 30px)">
                <ul class="nav nav-pills nav-fill mb-0" id="pills-tab" role="tablist" style="padding: 10px 20px; margin-top: 15px">
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" href="#" role="tab" aria-controls="pills-home" aria-selected="true" @click="switchTab(1)">电导率</a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" href="#" role="tab" aria-controls="pills-profile" aria-selected="false" @click="switchTab(2)">PH值</a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" href="#" role="tab" aria-controls="pills-contact" aria-selected="false" @click="switchTab(3)">土壤氮磷钾</a>
                  </li>
                </ul>
                <div id="chart4" style="width: 100%; height: calc(100% - 30px); margin-top: -30px"></div>
              </div>
            </div>
          </div>

          <div class="frame" style="height: 32%">
            <div class="title title5"></div>
            <div class="d-flex flex-column justify-content-center" style="height: calc(100% - 30px)">
              <video ref="video" src="https://openresource.nongxiao123.com/image/llxS9fjOICnz2l10LiBoExxsze7X.mp4"
                     controls autoplay style="max-height: 100%; width: 100%"></video>
              <!--autoplay-->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require('echarts/dist/echarts.min')

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  name: "Dashboard",
  data() {
    return {
      farm: {},
      chart1: undefined,
      chart2: undefined,
      chart3: undefined,
      chart4: undefined,
      // colour: '#caeeff',
      option1: {},
      option2: {},
      option3: {},
      option4: {},

      rows: [
        {
          no: 1,
          area: 155,
          crop: '玉米',
          date1: '2020.04.26',
          date2: '2020.04.26'
        },
        {
          no: 2,
          area: 170,
          crop: '黄瓜',
          date1: '2020.04.26',
          date2: '2020.04.26'
        },
        {
          no: 3,
          area: 120,
          crop: '胡萝卜',
          date1: '2020.04.26',
          date2: '2020.04.26'
        },
        {
          no: 4,
          area: 125,
          crop: '绿甘蓝',
          date1: '2020.04.26',
          date2: '2020.04.26'
        },
        {
          no: 5,
          area: 157,
          crop: '西兰花',
          date1: '2020.04.26',
          date2: '2020.04.26'
        }
      ],
      activeArea: 1,
      imgUrl1: require('@/assets/crop1_active.png'),
      imgUrl2: require('@/assets/crop2.png'),
      imgUrl3: require('@/assets/crop3.png'),
      imgUrl4: require('@/assets/crop4.png'),
      imgUrl5: require('@/assets/crop5.png'),
      ph: [
        require('@/assets/ph1.png'),
        require('@/assets/ph2.png'),
        require('@/assets/ph3.png'),
        require('@/assets/ph4.png'),
        require('@/assets/ph5.png'),
        require('@/assets/ph6.png'),
        require('@/assets/ph7.png'),
        require('@/assets/ph8.png'),
        require('@/assets/ph9.png'),
        require('@/assets/ph10.png'),
        require('@/assets/ph11.png'),
        require('@/assets/ph12.png'),
        require('@/assets/ph13.png'),
        require('@/assets/ph14.png'),
      ]
    }
  },
  // computed: {
  //   swiper() {
  //     return this.$refs.mySwiper.$swiper
  //   }
  // },
  created() {
    console.log('created')
  },
  mounted() {
    console.log('mounted')
    document.querySelector('body').style.backgroundColor = '#011B5D'
    this.randomiseData()
  },
  methods: {
    switchArea: function(index) {
      this.randomiseData()
      switch (index) {
        case 1:
          if (this.imgUrl1 === require('@/assets/crop1.png')) {
            this.imgUrl1 = require('@/assets/crop1_active.png')
            this.imgUrl2 = require('@/assets/crop2.png')
            this.imgUrl3 = require('@/assets/crop3.png')
            this.imgUrl4 = require('@/assets/crop4.png')
            this.imgUrl5 = require('@/assets/crop5.png')
          }
          break
        case 2:
          if (this.imgUrl2 === require('@/assets/crop2.png')) {
            this.imgUrl2 = require('@/assets/crop2_active.png')
            this.imgUrl1 = require('@/assets/crop1.png')
            this.imgUrl3 = require('@/assets/crop3.png')
            this.imgUrl4 = require('@/assets/crop4.png')
            this.imgUrl5 = require('@/assets/crop5.png')
          }
          break
        case 3:
          if (this.imgUrl3 === require('@/assets/crop3.png')) {
            this.imgUrl3 = require('@/assets/crop3_active.png')
            this.imgUrl1 = require('@/assets/crop1.png')
            this.imgUrl2 = require('@/assets/crop2.png')
            this.imgUrl4 = require('@/assets/crop4.png')
            this.imgUrl5 = require('@/assets/crop5.png')
          }
          break
        case 4:
          if (this.imgUrl4 === require('@/assets/crop4.png')) {
            this.imgUrl4 = require('@/assets/crop4_active.png')
            this.imgUrl1 = require('@/assets/crop1.png')
            this.imgUrl2 = require('@/assets/crop2.png')
            this.imgUrl3 = require('@/assets/crop3.png')
            this.imgUrl5 = require('@/assets/crop5.png')
          }
          break
        case 5:
          if (this.imgUrl5 === require('@/assets/crop5.png')) {
            this.imgUrl5 = require('@/assets/crop5_active.png')
            this.imgUrl1 = require('@/assets/crop1.png')
            this.imgUrl2 = require('@/assets/crop2.png')
            this.imgUrl3 = require('@/assets/crop3.png')
            this.imgUrl4 = require('@/assets/crop4.png')
          }
          break
      }
    },
    switchTab: function(index) {
      switch (index) {
        case 1:
          this.option4 = {
            color: ['#429EF8', '#FCEA54', '#E560FF'],
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true,
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: '#163161'
                }
              }
            },
            series: [
              {
                name: '电导率',
                type: 'line',
                data: [getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80)]
              }
            ]
          }
          break
        case 2:
          this.option4 = {
            color: ['#429EF8', '#FCEA54', '#E560FF'],
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: '#163161'
                }
              }
            },
            series: [
              {
                name: 'PH值',
                type: 'line',
                data: [5, 5, 6, 7, 6, 5, 6]
              }
            ]
          }
          break
        case 3:
          this.option4 = {
            color: ['#429EF8', '#FCEA54', '#E560FF'],
            tooltip: {
              trigger: 'axis'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: '#163161'
                }
              }
            },
            series: [
              {
                name: '氮',
                type: 'line',
                data: [getRandomInt(100, 180), getRandomInt(100, 180), getRandomInt(100, 180), getRandomInt(100, 180), getRandomInt(100, 180), getRandomInt(100, 180), getRandomInt(100, 180)]
              },
              {
                name: '磷',
                type: 'line',
                data: [getRandomInt(40, 80), getRandomInt(40, 80), getRandomInt(40, 80), getRandomInt(40, 80), getRandomInt(40, 80), getRandomInt(40, 80), getRandomInt(40, 80)]
              },
              {
                name: '钾',
                type: 'line',
                data: [getRandomInt(80, 160), getRandomInt(80, 160), getRandomInt(80, 160), getRandomInt(80, 160), getRandomInt(80, 160), getRandomInt(80, 160), getRandomInt(80, 160)]
              }
            ]
          }
          break
      }

      this.chart4.clear()
      // this.chart4.dispose()
      // this.chart4 = echarts.init(document.getElementById('chart4'))
      this.updateChart(this.chart4, this.option4)
    },
    updateChart: function(chart, options) {
      chart.setOption(options)
    },
    randomiseData: function() {
      this.farm = {
        guangzhao: getRandomInt(50, 100),
        kongqiwendu: getRandomArbitrary(0, 40).toFixed(1),
        kongqishidu: getRandomInt(10, 100),
        jiangshuiliang: getRandomArbitrary(0, 1).toFixed(2),
        zhengfaliang: getRandomArbitrary(0, 1).toFixed(2),
        co2: getRandomArbitrary(0, 1).toFixed(2),

        ph: getRandomInt(1, 14),
        turangwendu: getRandomArbitrary(0, 40).toFixed(1),
        turangshidu: getRandomInt(10, 100),
        danlinjia:  getRandomInt(100, 150) + '/' + getRandomInt(40, 100) + '/' + getRandomInt(80, 150)
      }

      this.chart1 = echarts.init(document.getElementById('chart1'))
      this.chart2 = echarts.init(document.getElementById('chart2'))
      this.chart3 = echarts.init(document.getElementById('chart3'))
      this.chart4 = echarts.init(document.getElementById('chart4'))

      let colour = '#caeeff'

      this.option1 = {
        title: {
          text: '土壤水分与空气湿度',
          textStyle: {
            color: colour,
            fontSize: 14
          },
        },
        color: ['#429EF8', '#FCEA54', '#E560FF'],
        tooltip: {
          trigger: 'axis',
          textStyle: {
            // color: colour
          },
        },
        legend: {
          data: ['土壤水分', '空气湿度'],
          right: 0,
          textStyle: {
            color: colour
          },
          icon: 'roundRect'
        },
        // grid: {
        //   show: true,
        //   left: '3%',
        //   right: '4%',
        //   bottom: '3%',
        //   containLabel: true,
        //   borderColor: 'red'
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#163161'
            }
          }
        },
        series: [
          {
            name: '土壤水分',
            type: 'line',
            itemStyle: {
              color: '#429EF8'
            },
            data: [getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100)]
          },
          {
            name: '空气湿度',
            type: 'line',
            itemStyle: {
              color: '#FCEA54'
            },
            data: [getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100), getRandomInt(10, 100)]
          }
        ]
      }

      this.option2 = {
        grid: {
          height: '60%'
        },
        title: {
          text: '土壤温度与空气湿度',
          textStyle: {
            color: colour,
            fontSize: 14
          },
        },
        color: ['#429EF8', '#FCEA54', '#E560FF'],
        tooltip: {},
        legend: {
          data:['土壤温度', '空气温度'],
          right: 0,
          textStyle: {
            color: colour
          },
        },
        xAxis: {
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              color: '#163161'
            }
          }
        },
        series: [
          {
            name: '土壤温度',
            type: 'bar',
            data: [getRandomInt(20, 40), getRandomInt(20, 40), getRandomInt(20, 40), getRandomInt(20, 40), getRandomInt(20, 40), getRandomInt(20, 40), getRandomInt(20, 40), getRandomInt(20, 40)]
          },
          {
            name: '空气温度',
            type: 'bar',
            data: [getRandomInt(20, 32), getRandomInt(20, 32), getRandomInt(20, 32), getRandomInt(20, 32), getRandomInt(20, 32), getRandomInt(20, 32), getRandomInt(20, 32), getRandomInt(20, 32)]
          }
        ]
      }

      let spirit = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAyElEQVQokaXSsUrCcRDA8Y9ugbuQW1G09AiBbiGCD2A0Obg2+CCutSk+gq21OpeL6QMIOmdCJCd/Q/iLmn2X34+7+3Jwd5mf6akNCmjiFmdY4A1dPOFrXbop3uERJ7YzRhWDyGaTkhraO6TgHC/Ju+qYxwi5VOl2XlGKjg9/kIIibkKspFL7KYd4cYR4mU2FDiTEjyO8YYi9VHg/z/9axwSNA6UZ6vFZDydu8R6fO6Q4udhhvL9i0MEVWsnAvjFHPzmSa7yvKrEEJ0woMJifbbYAAAAASUVORK5CYII='

      let maxData = 100

      this.option3 = {
        title: {
          text: '盐度（电导率）',
          left: '5%',
          // top: 0,
          textStyle: {
            color: colour,
            fontSize: 14
          }
        },
        xAxis: {
          max: maxData,
          splitLine: {show: false},
          offset: 10,
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          axisLabel: {
            show: false,
            margin: 10
          }
        },
        yAxis: {
          data: ['电导率'],
          inverse: true,
          axisTick: {show: false},
          axisLine: {show: false},
          axisLabel: {
            show: false,
            // margin: 10,
            // color: colour,
            // fontSize: 16
          }
        },
        grid: {
          show: true,
          top: 'center',
          borderWidth: 0,
          height: 24,
          left: '6%',
          right: '6%',
          width: '90%',
          // backgroundColor: '#1D3E64'
          backgroundColor: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
              offset: 0, color: 'rgba(19, 39, 86, 0.7)' // 0% 处的颜色
            }, {
              offset: 1, color: '#1D3E64' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        },
        series: [
          {
            // current data
            type: 'pictorialBar',
            symbol: spirit,
            symbolRepeat: 'fixed',
            symbolMargin: '15%',
            symbolClip: true,
            symbolSize: 10,
            symbolBoundingData: maxData,
            data: [getRandomInt(20, 90)],
            z: 10,
            label: {
              show: true,
              formatter: function (params) {
                return (params.value / maxData * 100).toFixed(1) + '%'
              },
              position: 'top',
              distance: 10,
              offset: [0, 0],
              color: '#ffec1a',
              fontSize: 14
            },
            itemStyle: {
            },
          },
          {
            // full data
            type: 'pictorialBar',
            itemStyle: {
              normal: {
                opacity: 0.2
              }
            },
            animationDuration: 0,
            symbolRepeat: 'fixed',
            symbolMargin: '15%',
            symbol: spirit,
            symbolSize: 10,
            symbolBoundingData: maxData,
            data: [100],
            z: 5
          }
        ]
      }

      this.option4 = {
        color: ['#429EF8', '#FCEA54', '#E560FF'],
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#163161'
            }
          }
        },
        series: [
          {
            name: '电导率',
            type: 'line',
            data: [getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80), getRandomInt(20, 80)]
          }
        ]
      }

      this.chart1.setOption(this.option1)
      this.chart2.setOption(this.option2)
      this.chart3.setOption(this.option3)
      this.chart4.setOption(this.option4)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-fluid {
  background-image: url("../assets/frame.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 10px;
  padding: 60px 20px;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
}

.hide {
  display: none;
}
#title:hover + .hide, .hide:hover {
  display: inline;
}

.frame {
  background: rgba(19, 39, 86, 0.7);
  //border: 2px solid #5694CD;
  border: 2px solid;
  //border-image: url("../assets/border1.png") 10 / 6px;
  //border-image: url("../assets/border.png") 27;
  border-radius: 4px;
  overflow: auto;
  border-image-source: url("../assets/border.png");
  //border-image-width: 20px;
  border-image-slice: 4;
  //border-image-width: 2;
  //border-image-repeat: repeat;
}

.frame-container {
  /*d-flex flex-column justify-content-evenly*/
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100% - 30px);
}

.title {
  width: 100%;
  height: 30px;
  background-color: #274F7F;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.title1 {
  background-image: url("../assets/title1.png"), linear-gradient(90deg, rgba(21,43,90,1) 0%, rgba(39,79,127,1) 50%, rgba(21,43,90,1) 100%);
}
.title2 {
  background-image: url("../assets/title2.png"), linear-gradient(90deg, rgba(21,43,90,1) 0%, rgba(39,79,127,1) 50%, rgba(21,43,90,1) 100%);
}
.title3 {
  background-image: url("../assets/title3.png"), linear-gradient(90deg, rgba(21,43,90,1) 0%, rgba(39,79,127,1) 50%, rgba(21,43,90,1) 100%);
}
.title4 {
  background-image: url("../assets/title4.png"), linear-gradient(90deg, rgba(21,43,90,1) 0%, rgba(39,79,127,1) 50%, rgba(21,43,90,1) 100%);
}
.title5 {
  background-image: url("../assets/title5.png"), linear-gradient(90deg, rgba(21,43,90,1) 0%, rgba(39,79,127,1) 50%, rgba(21,43,90,1) 100%);
}

/*#map {*/
/*  background: url("../image/map.png") no-repeat center center fixed;*/
/*}*/

.data-row {
  display: flex;
  justify-content: space-evenly;
  height: 50%;
  margin-top: 4px;
  .data-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 33%;
    .span1 {
      font-size: 18px;
    }
    .span2 {
      font-size: 16px;
    }
  }
}

.table {
  color: #caeeff;
  td, th {
    font-size: 15px;
  }
  tbody {
    tr:nth-child(odd) {
      background: #193565;
    }
    tr {
      td {
        padding: 0.5rem;
      }
    }
  }
}

.nav-link {
  color: #caeeff;
  font-size: 16px;
  padding: 0.1rem 0.2rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #caeeff;
  /*background-color: transparent;*/
  /*background: rgba(19, 39, 86, 0.9);*/
  background: #224F86;
  color: #70F5FC;
  border: 2px solid;
  border-image: url("../assets/border.png") 2;
}

</style>
